import { TaabiComponent } from '@app-shared/components/footers/taabi/taabi.component';
import { ClientConfig, DEFAULT_CLIENT_CONFIG } from './config.base';
import { navigationRoutes as BaseLinks, RouteParams } from './links.base';
import { FooterComponentItem } from '@app-shared/components/footers/footer.model';

export const CLIENT_CONFIG: ClientConfig = {
  clientName: 'taabi',
  ...DEFAULT_CLIENT_CONFIG,
  logo: {
    lightLogo: 'assets/taabi/light-logo.svg',
    darkLogo: 'assets/taabi/dark-logo.svg',
    alt: 'Taabi',
    width: '100px',
    height: '30px',
  },
  showLandingPage: true,
  landingPageDetails: {
    backgroundImage: {
      src: 'assets/taabi/taabi-banner-bg.png',
    },
    brandImage: {
      src: 'assets/taabi/light-logo.svg',
    },
    headerImage: {
      src: 'assets/taabi/light-logo.svg',
    },
    tagline: 'Making Every Journey Profitable',
    footer: new FooterComponentItem(TaabiComponent),
  },
};

export const navigationRoutes: RouteParams[] = CLIENT_CONFIG.allowedRoutes.map((x) => BaseLinks[x]);
